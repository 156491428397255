export default {
  area: '汤阴县城关镇智慧社区综合服务平台',
  objectName: '',
  //url
  hostUrl: 'http://jlhyjd.anluoyun.cn',
  apiHttpsUrl: 'https://anyangapi.zhsq.cloud/',
  apiRequestUrl: 'https://anyangapi.zhsq.cloud/api',
  apiUploadUrl: 'https://anyangapi.zhsq.cloud/api/UploadFile/UploadImgOrFile',
  apiUploadFiles: 'https://anyangapi.zhsq.cloud/api/UploadFile/UploadImgOrFile',
  apiUploadvideo:
    'https://anyangapi.zhsq.cloud/api/UploadFile/UploadQiniuVideo',
}
